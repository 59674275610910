import React, { useEffect, useMemo, useState } from 'react';

import { Box, Grid, useTheme } from '@mui/material';

import styles from './ImagesGrid.module.css';

import { SortableImageField } from '../../api/ImageApiTypes';
import { Image, ImageState, ImageStates } from '../../api/caseApiTypes';
import { FilterableImage } from '../../api/filterTypes';
import { LoadMoreItem } from '../../commonComponents/LoadMoreItem';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { SearchContext, useImageSearchState } from '../../hooks/useSearchState';
import { LocalStorageKeys, SessionStorageKeys, useLocalStorage, useSessionStorage } from '../../hooks/useStorage';
import { useUserFilters } from '../../hooks/useUserFilters';
import { ImageCard } from '../image/imageCard/ImageCard';
import { ImagePropClauseResolverConfig } from '../sidebar/filter/propClauseResolver/resolverConfigs';
import { SearchFilters, SearchFilterTranslator } from '../sidebar/filter/searchFilterTranslator';
import { ImageGridDetailViewImageSource, ImagesGridDetailsView } from './detailsView/ImagesGridDetailsView';
import { ImagesSearchPanel } from './ImagesSearchPanel';
import { isAnyMediaSelected, resetSelectedContext, search } from './imagesSlice';
import { ImageFullScreenDisplay } from './ImageFullScreenDisplay';
import { CreateRetouchCaseFromSelectionButton } from './selectedImagesContext/CreateRetouchCaseFromSelectionButton';
import { DownloadSelectedMediaButton } from './selectedImagesContext/DownloadSelectedMediaButton';
import { AddSelectedToClipbookIconButton } from './selectedImagesContext/AddSelectedToClipbookIconButton';
import { useClearSelectedMedia } from './selectedImagesContext/useClearSelectedMedia';
import { useSelectionContext } from './selectedImagesContext/selectionContextContext';
import { OutlinedLabel } from '../../helpers/OutlinedLabel';
import { ToggleImageSourceButton } from './detailsView/imagesGridDetailsViewToggleImageSource';
import { selectActiveClipbook } from '../clipbooks/clipbooksSlice';

const pageSize = 50;

const translator = new SearchFilterTranslator<FilterableImage>(ImagePropClauseResolverConfig);

const imageGridFilters: SearchFilters<FilterableImage> = {
  defaultFilters: {
    state: { value: ImageStates.AllExcept([ImageState.Archived, ImageState.Deleted]).map(state => state.toString()) },
  },
};

enum ImageProductionApprovalState {
  Approved,
  ApprovedWhenPublished,
  NotApproved,
}

function getIsApproved(image: Image): ImageProductionApprovalState {
  if (image.state === ImageState.Approved) {
    return ImageProductionApprovalState.Approved;
  } else if (image.state === ImageState.ProofedOk) {
    // If the image is in the state Proofed OK, and the publication date has been reached, the image is
    // considered Approved.
    if (image.publicationDate && new Date(image.publicationDate) < new Date()) {
      return ImageProductionApprovalState.Approved;
    } else {
      return ImageProductionApprovalState.ApprovedWhenPublished;
    }
  }
  return ImageProductionApprovalState.NotApproved;
}

export const ImagesGrid: React.FC = () => {
  const dispatch = useAppDispatch();
  const [continuation, setContinuation] = useState(0);
  const [imageSource, setImageSource] = useState<ImageGridDetailViewImageSource>('selected');

  const { palette } = useTheme();

  const isLightMode = palette.mode === 'light';

  const [query, setQuery] = useSessionStorage(SessionStorageKeys.searchQuery, '');
  const [searchOnlyFilename, setSearchOnlyFilename] = useSessionStorage(SessionStorageKeys.imageSearchOnlyFilename, false);

  const [orderBy, setOrderBy] = useLocalStorage<SortableImageField>(LocalStorageKeys.imageGridOrderBy, 'Created');
  const [orderDesc, setOrderDesc] = useLocalStorage<boolean>(LocalStorageKeys.imageGridOrderDesc, true);

  const { items: images, count, isSearching } = useImageSearchState(SearchContext.ImagesGrid);
  const userFilters = useUserFilters(SearchContext.ImagesGrid);

  const selectionContext = useSelectionContext();
  const hasImagesInActiveClipbook = useAppSelector(state => (selectActiveClipbook(state)?.images ?? []).length > 0);
  const closeDetails = () => {
    setImageSource('selected');
    dispatch(resetSelectedContext(selectionContext));
  };
  const anySelected = useAppSelector(isAnyMediaSelected(selectionContext));
  const showDetails = useMemo(
    () => (imageSource === 'selected' && anySelected) || (imageSource === 'clipbook' && hasImagesInActiveClipbook),
    [imageSource, anySelected, hasImagesInActiveClipbook],
  );

  useClearSelectedMedia();

  const canLoadMore = count > images.length;

  const loadMore = () => setContinuation(images.length);

  useEffect(() => {
    if (userFilters) {
      dispatch(
        search({
          query,
          orderBy: [{ id: orderBy, desc: orderDesc }],
          filterTree: translator.getClauses({ ...imageGridFilters, userFilters }),
          continuation,
          pageSize,
          searchOnlyFilename,
          searchContext: SearchContext.ImagesGrid,
        }),
      );
    }
  }, [dispatch, query, userFilters, continuation, orderBy, orderDesc, searchOnlyFilename]);

  useEffect(() => {
    setContinuation(0);
  }, [query, userFilters, orderBy, orderDesc, searchOnlyFilename]);

  useEffect(() => {
    dispatch(resetSelectedContext(selectionContext));
  }, [query, dispatch, userFilters, orderBy, orderDesc, searchOnlyFilename, selectionContext]);

  const getStyle = (image: Image) => {
    const style: React.CSSProperties = {
      borderStyle: 'solid',
      borderWidth: '1px',
    };

    const approvedState = getIsApproved(image);
    if (approvedState === ImageProductionApprovalState.Approved) {
      style.borderColor = isLightMode ? '#fff' : 'transparent';
    } else if (approvedState === ImageProductionApprovalState.ApprovedWhenPublished) {
      style.borderColor = isLightMode ? '#fff6bb' : '#888600';
      style.backgroundColor = isLightMode ? '#fffef1' : '#201d0c';
    } else {
      style.borderColor = isLightMode ? '#fbb' : '#800';
      style.backgroundColor = isLightMode ? '#fff1f1' : '#200c0c';
    }

    return style;
  };

  const getImageCard = (image: Image) => {
    return <ImageCard key={image.id} style={getStyle(image)} image={image} disableRemoval />;
  };

  const gridClasses = [styles.imageGrid];
  if (showDetails) {
    gridClasses.push(styles.compact);
  }

  const additionalButtons = (
    <>
      {anySelected && (
        <OutlinedLabel label="Markerade bilder">
          <AddSelectedToClipbookIconButton />
          <CreateRetouchCaseFromSelectionButton />
          <DownloadSelectedMediaButton />
        </OutlinedLabel>
      )}
      <ToggleImageSourceButton source={imageSource} onClick={setImageSource} />
    </>
  );

  return (
    <>
      <Box className={styles.container}>
        <ImagesSearchPanel
          className={styles.searchPanel}
          onSearch={setQuery}
          isSearching={isSearching}
          count={count}
          searchOnlyFileName={searchOnlyFilename}
          toggleSearchOnlyFileName={() => setSearchOnlyFilename(!searchOnlyFilename)}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderDesc={orderDesc}
          toggleOrderDesc={() => setOrderDesc(value => !value)}
          additionalButtons={additionalButtons}
        />
        <Box className={styles.content}>
          <Grid container sx={{ gap: '0.5em' }} className={gridClasses.join(' ')}>
            {images.map(getImageCard)}
            {canLoadMore && (
              <Grid item className={styles.loadMoreWrapper}>
                <LoadMoreItem
                  className={styles.loadMoreContainer}
                  itemsLoaded={images.length}
                  count={count}
                  pageSize={pageSize}
                  isSearching={isSearching}
                  loadMore={loadMore}
                />
              </Grid>
            )}
          </Grid>
          {showDetails && (
            <Box className={styles.details}>
              <ImagesGridDetailsView onClose={closeDetails} imageSource={imageSource} />
            </Box>
          )}
        </Box>
      </Box>
      <ImageFullScreenDisplay />
    </>
  );
};
