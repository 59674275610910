import React from "react";
import { CustomNode } from './CustomNode';
import { MediaGroupNode } from '../Types';

export type Props = {
  favorites: MediaGroupNode[],
  onSelect: (mediaGroupTreeNode: MediaGroupNode) => void;
};

export function MediaGroupFavorites({ favorites, onSelect }: Props) {

  return (
    <>
        {favorites.map((node, index) => (
          <>
            <CustomNode
              key={`MediaGroupFavorites_CustomNode_${index}`}
              node={node}
              isEditMode={false}
              isQueryMode={false}
              disableFavoriteToggle={true}
              disableSelected={true}
              disableOnSelect={true}
              depth={0}
              isOpen={false}
              isSelected={false}
              onToggle={() => {}}
              onSelect={onSelect}
            />
          </>
        ))}
    </>
  );
}
