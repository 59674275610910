import React, {useEffect, useMemo} from 'react';
import styles from './Sidebar.module.css';
import { Right } from '../../api/userApi';
import {
  Badge,
  Divider,
  Drawer,
  List,
  Toolbar
} from '@mui/material';
import { NewCaseButton } from './NewCaseButton';
import {
  Event,
  PhotoLibrary,
  Schedule,
  ViewDay,
  Assignment,
  ShoppingCart,
  PermMedia
} from '@mui/icons-material';
import { SidebarMenuItem } from './SidebarMenuItem';
import { FilterPanel } from './FilterPanel';
import { SearchContext, useCaseSearchState } from '../../hooks/useSearchState';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { search } from '../cases/casesSlice';
import { ProductContextualKanbanSupportedTypes } from '../kanban/ProductContextualCaseKanbanPlan';
import { Case, StateEnum, States } from '../../api/caseApiTypes';
import { SearchFilterTranslator, SearchFilters } from './filter/searchFilterTranslator';
import { FilterableCase } from '../../api/filterTypes';
import { CasePropClauseResolverConfig } from './filter/propClauseResolver/resolverConfigs';
import { KanbanSupportedTypes } from '../kanban/CaseKanbanPlan';
import { Routes } from '../../App';

interface SidebarFragment {
  icon?: JSX.Element;
  label?: string;
  children?: SidebarLink[];
  right?: Right[] | Right;
}

export interface SidebarLink extends SidebarFragment {
  path: string;
}

export interface SidebarComponent extends SidebarFragment {
  component: JSX.Element;
}

interface SidebarSubmenu extends SidebarFragment {
  children: SidebarLink[];
}

function useSearchForAssignedCases(searchContext: SearchContext, filters: SearchFilters<FilterableCase>) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const translator = new SearchFilterTranslator<FilterableCase>(CasePropClauseResolverConfig);

    dispatch(
      search({
        filterTree: translator.getClauses(filters),
        pageSize: 100,
        searchContext: searchContext,
      }),
    );
  }, [dispatch, searchContext, filters]);
}

export type SidebarEntry = SidebarLink | SidebarSubmenu | SidebarComponent;

function countCasesWithState(cases: Case[], state: StateEnum) {
  let count = 0;
  for (let c of cases) {
    if (c.state.state === state) {
      count += 1;
    }
  }
  return count;
}

function useKanbanFilters(photographer: string | undefined) {
  return useMemo(() => {
    const states = States.AllExcept([StateEnum.closed]);
    const caseTypes = KanbanSupportedTypes;
    return {
      userFilters: {
        type: { value: caseTypes.map(caseType => caseType.toString()) },
        Case_CaseState: {
          value: states.map(s => s.toString()),
        },
        photographer: {
          value: [photographer || ''],
        },
      },
    };
  }, [photographer]);
}

function useProductContextualKanbanFilters(photographer: string | undefined) {
  return useMemo(() => {
    const states = States.AllExcept([StateEnum.closed]);
    const caseTypes = ProductContextualKanbanSupportedTypes;
    return {
      userFilters: {
        type: { value: caseTypes.map(caseType => caseType.toString()) },
        Case_CaseState: {
          value: states.map(s => s.toString()),
        },
        photographer: {
          value: [photographer || ''],
        },
      },
    };
  }, [photographer]);
}

export const Sidebar: React.FC = () => {
  const photographers = useAppSelector(state => state.app.enumerations?.photographers);
  const currentUserId = useAppSelector(state => state.app.user?.id);
  const photographer = photographers?.find(p => p.userId === currentUserId)?.key;

  const { items: activeKanbanTickets } = useCaseSearchState(SearchContext.KanbanBadge);
  const { items: activeProductContextualKanbanTickets } = useCaseSearchState(SearchContext.ContextualKanbanBadge);

  const numActiveKanbanTickets = countCasesWithState(activeKanbanTickets, StateEnum.planned);
  const numActiveProductContextualKanbanTickets = countCasesWithState(activeProductContextualKanbanTickets, StateEnum.planned);

  const kanbanFilters = useKanbanFilters(photographer);
  const productContextualKanbanFilters = useProductContextualKanbanFilters(photographer);

  useSearchForAssignedCases(SearchContext.KanbanBadge, kanbanFilters);
  useSearchForAssignedCases(SearchContext.ContextualKanbanBadge, productContextualKanbanFilters);

  const sidebarEntries: SidebarEntry[] = [
    {
      icon: <PhotoLibrary />,
      label: 'Mediabank',
      path: Routes.Images,
    },
    {
      icon: <ShoppingCart />,
      label: 'Beställningar',
      path: Routes.Cases,
    },
    {
      icon: <Event />,
      label: 'Planera',
      children: [
        {
          icon: <Schedule />,
          label: 'Kalender',
          path: Routes.CalendarPlanning,
        },
        {
          icon: (
            <Badge badgeContent={numActiveKanbanTickets} color="secondary">
              <ViewDay />
            </Badge>
          ),
          label: 'Kanban',
          path: Routes.KanbanPlanning,
        },
        {
          icon: (
            <Badge badgeContent={numActiveProductContextualKanbanTickets} color="secondary">
              <Assignment />
            </Badge>
          ),
          label: 'Produktbild miljö kanban',
          path: Routes.ProductContextualKanbanPlanning,
        },
      ],
    },
    {
      icon: <PermMedia />,
      label: 'Grupper',
      path: Routes.MediaGroups,
    },
    {
      component: <Divider style={{margin: '0.5em'}} />,
    },
    {
      component: <FilterPanel />,
    },
  ];

  return (
    <>
    <Drawer variant="permanent" className={styles.drawer} classes={{paper: styles.drawerPaper}}>
      <div>
        <Toolbar/>
        <div className={styles.content}>
          <NewCaseButton/>
        </div>
        <List>
          {sidebarEntries.map((entry, index) => (
            <SidebarMenuItem key={`sidebarItem_${index}`} item={entry}/>
          ))}
        </List>
      </div>
    </Drawer>
    </>
  );
};
