import { MediaGroupType } from "../../api/mediaGroupApi";

export interface MediaGroupNode {
  id: string;
  type: MediaGroupType;
  name: string;
  description: string;
  isPersonal: boolean;
  parentId: string;
  images: string[];
  createdById: string;
  createdByFullName: string;
  created: string;
  modifiedById: string;
  modifiedByFullName: string
  modified: string;

  text: string;
  parent: string;
  droppable: boolean;
}

export interface FavoriteMediaGroup {
  id: string,
  name: string,
}

export enum ContextMenuChoice {
  Add = 1,
  Rename = 2,
  Delete = 3
}

export interface DialogData {
  node: any;
  isPersonal: boolean;
}

export interface Clipbook {
  name: string;
  images: string[];
}
