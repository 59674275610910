import React, { useMemo, useState } from 'react';
import {alpha, AppBar, Button, Divider, IconButton, Menu, MenuItem, MenuProps, styled, Toolbar, Typography, useTheme } from '@mui/material';
import {
  Brightness4,
  Brightness7,
  Logout,
  Search,
  Settings,
  ViewInArTwoTone
} from '@mui/icons-material';

import { M3SearchModal } from '../m3Search/M3SearchDialog';

import styles from './Header.module.css';
import logo from '../../images/logo.svg';

import { Right, User } from '../../api/userApi';
import { useEitherRights } from '../../hooks/useRights';
import { AdminPanel } from '../admin/AdminPanel';
import { useEnvironment } from '../../hooks/useEnvironment';

interface Props {
  user: User | null;
  darkModeEnabled: boolean;
  toggleDarkMode: () => void;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
      backgroundColor: theme.palette.grey[900]
    }),
  },
}));


export const Header: React.FC<Props> = ({ user, darkModeEnabled, toggleDarkMode }) => {
  const environment = useEnvironment();
  const canWriteTags = useEitherRights([Right.Administration, Right.WriteTags]);
  const { palette, zIndex } = useTheme();
  const [administrationOpen, setAdministrationOpen] = useState(false);
  const [m3SearchModalIsVisible, setM3SearchModalIsVisible] = useState(false);

  const paletteColour = palette.header ?? palette.primary;
  const backgroundColor = paletteColour.main;

  const ModeIcon = darkModeEnabled ? Brightness7 : Brightness4;
  const modeText = (darkModeEnabled ? 'Avaktivera' : 'Aktivera') + ' dark mode';
  const contrastTextColour = paletteColour.contrastText;

  const contrastTextIsDark = useMemo(() => {
    const match = contrastTextColour?.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    if (!Array.isArray(match) || match.length < 3) return false;

    const { r, g, b } = {
      r: Number(match[1]),
      g: Number(match[2]),
      b: Number(match[3]),
    };

    return r < 30 && g < 30 && b < 30;
  }, [contrastTextColour]);

  const signOut = () => (window.location.href = '/user/logout');

  let env = null;
  if (window.location.hostname === 'localhost') {
    env = 'DEV';
  } else if (window.location.hostname.includes('test')) {
    env = 'TEST';
  } else if (window.location.hostname.includes('qa')) {
    env = 'QA';
  }

  const logoStyle: React.CSSProperties = {
    filter: contrastTextIsDark ? 'invert(100%)' : undefined,
  };

  let subDomain3DPortal = environment === 'prod' ? 'sortiment' : 'sortiment-test';
  const open3dPortal = () => window.open(`https://${subDomain3DPortal}.mio.photo/?portal=true`, '_blank');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openUserMenu = Boolean(anchorEl);
  const handleClickUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };


  return (
    <AppBar className={styles.container} position="fixed" style={{ zIndex: zIndex.drawer + 1, backgroundColor }}>
      <Toolbar variant="dense" className={styles.toolbar} style={{ color: contrastTextColour }}>
        <div className={styles.left}>
          <img src={logo} alt="Mio Mediaportal" className={styles.logo} style={logoStyle} />
          {env && (
            <Typography>
              <strong>{env}</strong>
            </Typography>
          )}
        </div>

        <div className={styles.user}>
          <IconButton onClick={open3dPortal} title="Öppna 3D portalen">
            <ViewInArTwoTone style={{color: contrastTextColour}}/>
          </IconButton>
          <IconButton onClick={() => setM3SearchModalIsVisible(true)} title="Sök artikel">
            <Search style={{color: contrastTextColour}}/>
          </IconButton>
          {canWriteTags && (
            <IconButton onClick={() => setAdministrationOpen(!administrationOpen)}>
              <Settings style={{color: contrastTextColour}}/>
            </IconButton>
          )}

          <div>
            <Button
              id="demo-customized-button"
              aria-controls={openUserMenu ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openUserMenu ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClickUserMenu}
            >
              {user?.fullName || user?.email || user?.id || 'null'}
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={openUserMenu}
              onClose={handleCloseUserMenu}
            >
              <MenuItem  onClick={toggleDarkMode} disableRipple>
                <ModeIcon />
                {modeText}
              </MenuItem>
              <Divider sx={{my: 0.5}}/>
              <MenuItem onClick={() => signOut()} disableRipple>
                <Logout />
                Logga ut
              </MenuItem>
            </StyledMenu>
          </div>
        </div>
      </Toolbar>
      <AdminPanel isVisible={administrationOpen} onClose={() => setAdministrationOpen(!administrationOpen)}/>
      <M3SearchModal selectMultiple={false} isVisible={m3SearchModalIsVisible}
                     onClose={() => setM3SearchModalIsVisible(false)}/>
    </AppBar>
  );
};
