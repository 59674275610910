import React, { useState } from "react";
import { AddGroupDialog } from "./AddGroupDialog";
import { Box, IconButton, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

export interface MediaGroupListHeaderProps {
  title: string;
  isEditMode: boolean;
  isPersonal: boolean;
}

export const MediaGroupListHeader: React.FC<MediaGroupListHeaderProps> = ({ title, isEditMode, isPersonal }) => {
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);

  return (
    <>
      <Box>
        <Typography style={{display: 'inline'}} variant="body2" color="textSecondary">{title}</Typography>
        {isEditMode && (
          <IconButton onClick={(event) => {
            setShowAddDialog(true);
          }}>
            <Add/>
          </IconButton>
        )}
      </Box>

      <AddGroupDialog
        isPersonal={isPersonal}
        isVisible={showAddDialog}
        closeDialog={() => {
          setShowAddDialog(false)
        }}
      />

    </>
  );
}
