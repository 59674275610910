import { combineReducers } from '@reduxjs/toolkit';

import appReducer from '../appSlice';
import clipbooksReducer from '../features/clipbooks/clipbooksSlice';
import mediaGroupsReducer from '../features/mediaGroups/mediaGroupsSlice';
import casesReducer from '../features/cases/casesSlice';
import caseDetailReducer from '../features/cases/caseDetailSlice';
import localUserStateReducer from '../features/localUserStateSlice';
import imagesReducer from '../features/images/imagesSlice';
import tagsReducer from '../features/tags/TagsSlice';
import m3Reducer from '../features/m3Search/m3Slice';

const rootReducer = combineReducers({
  app: appReducer,
  clipbooks: clipbooksReducer,
  mediaGroups: mediaGroupsReducer,
  cases: casesReducer,
  caseDetail: caseDetailReducer,
  localUserState: localUserStateReducer,
  images: imagesReducer,
  tags: tagsReducer,
  m3: m3Reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
