import React from "react";
import styles from "./CustomDragPreview.module.css";
import {useTheme} from "@mui/material";

export interface CustomDragPreviewProps {
  monitorProps: any;
}

export const CustomDragPreview: React.FC<CustomDragPreviewProps> = ({ monitorProps}) => {
  const palette = useTheme();
  const item = monitorProps.item;

  return (
    <div className={`${styles.root} ${palette.palette.mode === 'dark' ? styles.rootDark : styles.rootLight}`}>
      <div className={styles.label}>{item.text}</div>
    </div>
  );
};
