import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useAppRedux";
import { addToFavorite, deleteFromFavorite, isMediaGroupAFavorite } from "../mediaGroupsSlice";
import { Folder, PushPin, PushPinOutlined } from "@mui/icons-material";
import {Box, IconButton } from "@mui/material";
import { MediaGroupNode } from '../Types';
import { MediaGroupType } from "../../../api/mediaGroupApi";

export interface CustomNodeTypeIconProps {
  node: MediaGroupNode;
  show: boolean;
  isDisabled?: boolean;
  disableFavoriteToggle?: boolean;
}

export const CustomNodeTypeIcon: React.FC<CustomNodeTypeIconProps> = ({ node, show, isDisabled, disableFavoriteToggle}) => {
  const dispatch = useAppDispatch();
  const isFavorite = useAppSelector(isMediaGroupAFavorite(node.id));

  const onToggleFavorite = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (disableFavoriteToggle) {
      return;
    }

    if (isFavorite) {
      dispatch(deleteFromFavorite({ id: node.id}));
    } else {
      dispatch(addToFavorite({ node: node}));
    }
  }

  return (
    <>
        {node.type === MediaGroupType.Folder ? (
          <>
            <IconButton onClick={() => {}}><Folder /></IconButton>
          </>
        ) : (
          <>
            {show ? (
              <IconButton disabled={isDisabled} onClick={onToggleFavorite}>
                {isFavorite === true ? (
                  <PushPin style={{height: '0.9em', width: '0.9em'}} />
                ) : (
                  <PushPinOutlined style={{height: '0.9em', width: '0.9em'}} />
                )
                }
              </IconButton>
            ) : (
              <Box style={{height: '0.9em', margin: '8px 23px 8px 0px', width: '0.9em'}}>&nbsp;</Box>
            )}
          </>
        )}
    </>
  );
};
