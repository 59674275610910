import { Fragment } from 'react';
import { BookmarkRemove } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import { removeImagesFromClipbook, selectActiveClipbook } from '../clipbooksSlice';
import { Props } from './AddToClipbookIconButton';
import {deleteImagesFromMediaGroup, getSelectedMediaGroup} from '../../mediaGroups/mediaGroupsSlice';
import { useSelectionContext } from '../../images/selectedImagesContext/selectionContextContext';
import { resetSelectedContext } from '../../images/imagesSlice';

export function RemoveFromClipbookIconButton({ imageIds, title }: Props) {
  const dispatch = useAppDispatch();
  const selectionContext = useSelectionContext();
  const activeMediaGroup = useAppSelector(getSelectedMediaGroup);

  if (!activeMediaGroup) {
    return <Fragment />;
  }

  const onRemove = () => {
    if (activeMediaGroup.images.every(imageId => !imageIds.includes(imageId))) return;
    if (window.confirm('Är du säker på att du vill ta bort bilderna från den aktiva gruppen?') === false) return;

    dispatch(deleteImagesFromMediaGroup({ id: activeMediaGroup.id, images: imageIds }));
    dispatch(resetSelectedContext(selectionContext));
  };
  const bildText = imageIds.length === 1 ? 'bilden' : 'bilderna';
  const buttonText = title ?? `Ta bort ${bildText} från den aktiva gruppen`;

  return (
    <IconButton title={buttonText} onClick={onRemove}>
      <BookmarkRemove />
    </IconButton>
  );
}
