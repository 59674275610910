import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/rootReducer';
import { LocalStorageKeys } from '../../hooks/useStorage';
import { MediaGroupEntity, MediaGroupType } from '../../api/mediaGroupApi';
import { ThunkConfig } from '../../app/store';
import * as Api from '../../api/mediaGroupApi';
import { pushToast } from '../../appSlice';
import { Clipbook, FavoriteMediaGroup, MediaGroupNode } from './Types';

interface MediaGroupState {
  isLoading: {
    private: boolean;
    public: boolean;
  },
  isSearching: boolean,
  isFetching: Record<string, boolean>;
  favorites: FavoriteMediaGroup[];
  mediaGroups: Record<string, MediaGroupNode>;
  activeMediaGroup: MediaGroupNode | undefined;
  queryMediaGroups: Record<string, MediaGroupNode>;
}

const emptyState: MediaGroupState = {
  isLoading: {
    private: false,
    public: false
  },
  isSearching: false,
  isFetching: {},
  favorites: [],
  mediaGroups: {},
  activeMediaGroup: undefined,
  queryMediaGroups: {}
};

const storeFavorites = (mediaGroups: FavoriteMediaGroup[]) => window.localStorage.setItem(LocalStorageKeys.favoriteMediaGroups, JSON.stringify(mediaGroups));
const fetchClipbooks = () => JSON.parse(window.localStorage.getItem(LocalStorageKeys.clipbooks) || JSON.stringify({}));
const deleteClipbooks = () => window.localStorage.removeItem(LocalStorageKeys.clipbooks);
const fetchFavorites = (): FavoriteMediaGroup[] => {
  return JSON.parse(window.localStorage.getItem(LocalStorageKeys.favoriteMediaGroups) || JSON.stringify(emptyState.favorites))
}

const initialState: MediaGroupState = {
  isLoading: {
    private: false,
    public: false
  },
  isSearching: false,
  isFetching: {},
  favorites: fetchFavorites(),
  mediaGroups: {},
  activeMediaGroup: undefined,
  queryMediaGroups: {}
};

export const mediaGroupsSlice = createSlice({
  name: 'mediagroups',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = {
        private: true,
        public: true
      };
    },
    stopLoadingPrivate(state) {
      state.isLoading = {
        ...state.isLoading,
        private: false
      };
    },
    stopLoadingPublic(state) {
      state.isLoading = {
        ...state.isLoading,
        public: false
      };
    },
    startSearching(state) {
      state.queryMediaGroups = {};
      state.isSearching = true;
    },
    startFetching(state, { payload: { id } }: PayloadAction<{ id: string }>) {
      state.isFetching[id] = true;
    },
    stopFetching(state, { payload: { id } }: PayloadAction<{ id: string }>) {
      state.isFetching[id] = false;
    },
    searchMediaGroupsSuccess(state, { payload: { nodes } }: PayloadAction<{ nodes: MediaGroupNode[] }>) {
      state.queryMediaGroups = {};
      for (var i = 0; i < nodes.length; i++) {
        state.queryMediaGroups[nodes[i].id] = nodes[i];
      }
      state.isSearching = false;
    },
    loadMediaGroupsSuccess(state, { payload: { nodes } }: PayloadAction<{ nodes: MediaGroupNode[] }>) {
      for (var i = 0; i < nodes.length; i++) {
        state.mediaGroups[nodes[i].id] = nodes[i];
      }
    },
    addMediaGroupSuccess(state, { payload: { node } }: PayloadAction<{ node: MediaGroupNode }>) {
      delete state.mediaGroups[`${node.parentId}__empty`];
      state.mediaGroups[node.id] = node;
    },
    updateMediaGroupSuccess(state, { payload: { node } }: PayloadAction<{ node: MediaGroupNode }>) {
      state.mediaGroups[node.id] = node;
    },
    deleteMediaGroupSuccess(state, { payload: { id } }: PayloadAction<{ id: string }>) {
      delete state.mediaGroups[id];
      if (isMediaGroupAFavorite(id)) {
        const index = state.favorites.findIndex(favorite => favorite.id === id);
        state.favorites.splice(index, 1);
        storeFavorites(state.favorites);
      }
    },

    updateMediaGroupPreApi(state, { payload: { node } }: PayloadAction<{ node: MediaGroupNode }>) {
      state.mediaGroups[node.id] = node;
    },
    addImagesToMediaPreApi(state, { payload: { id, images } }: PayloadAction<{ id: string, images: string[] }>) {
      let updatedMediaGroup = state.mediaGroups[id];
      // Filter the images that are already added to the MediaGroup
      images = images.filter(imageId => updatedMediaGroup.images.findIndex(p => p === imageId) < 0);

      updatedMediaGroup.images.push(...images);
      state.mediaGroups[id] = updatedMediaGroup;
    },
    deleteImagesFromMediaPreApi(state, { payload: { id, images } }: PayloadAction<{ id: string, images: string[] }>) {
      let updatedMediaGroup = state.mediaGroups[id];
      for (const imageId of images) {
        const index = updatedMediaGroup.images.findIndex(p => p === imageId);
        if (index < 0) {
          continue;
        }
        updatedMediaGroup.images.splice(index, 1);
      }
      state.mediaGroups[id] = updatedMediaGroup;
    },

    selectMediaGroupSuccess(state, { payload: { node } }: PayloadAction<{ node: MediaGroupNode }>) {
      state.activeMediaGroup = node;
    },
    updateActiveMediaGroup(state, { payload: { node } }: PayloadAction<{ node: MediaGroupNode }>) {
      if (state.activeMediaGroup?.id === node.id) {
        const updatedMediaGroupTreeNode2 = {
          ...state.activeMediaGroup,
          name: node.name,
          text: node.name,
          isPersonal: node.isPersonal,
          parentId: node.parentId,
          parent: node.parent,
          images: node.images,
          description: node.description
        };
        state.activeMediaGroup = updatedMediaGroupTreeNode2;
      }
    },

    addToFavorite(state, { payload: { node } }: PayloadAction<{ node: MediaGroupNode }>) {
      var favorite = {
        id: node.id,
        name: node.name,
        type: node.type as number,
      };

      state.favorites.push(favorite);
      storeFavorites(state.favorites);
    },
    deleteFromFavorite(state, { payload: { id } }: PayloadAction<{id: string }>) {
      const index = state.favorites.findIndex(favorite => favorite.id === id);
      state.favorites.splice(index, 1);
      storeFavorites(state.favorites);
    },

    addImagesToMediaGroupSuccess(state, { payload: { node } }: PayloadAction<{ node: MediaGroupNode }>) {
      state.mediaGroups[node.id] = node;
    },
    deleteImagesFromMediaGroupSuccess(state, { payload: { node } }: PayloadAction<{ node: MediaGroupNode }>) {
      state.mediaGroups[node.id] = node;
    }
  },
});

export const {
  startLoading,
  stopLoadingPrivate,
  stopLoadingPublic,
  startSearching,
  startFetching,
  stopFetching,
  searchMediaGroupsSuccess,

  loadMediaGroupsSuccess,
  addMediaGroupSuccess,
  updateMediaGroupSuccess,
  deleteMediaGroupSuccess,

  addImagesToMediaPreApi,
  updateMediaGroupPreApi,
  deleteImagesFromMediaPreApi,

  addToFavorite,
  deleteFromFavorite,

  selectMediaGroupSuccess,
  updateActiveMediaGroup,

  addImagesToMediaGroupSuccess,
  deleteImagesFromMediaGroupSuccess
} =
  mediaGroupsSlice.actions;

export const initialiseMediaGroups = createAsyncThunk<void, void, ThunkConfig>('media-groups/initialize', async (_, { dispatch, getState }) => {
  const { isInitialized } = getState().app;
  if (isInitialized) {
    throw Error("Undefined behaviour, already initialised SPA can't be re-initialised");
  }

  // Migrate old Clippbooks to MediaGroups
  var clipbooks = fetchClipbooks() as Clipbook[];
  if (clipbooks.length > 0) {
    for (var i = 0; i < clipbooks.length; i++) {
      let mediaGroupEntity = await Api.add(clipbooks[i].name, 2, true, undefined, clipbooks[i].images);
    }
    deleteClipbooks();
  }

  dispatch(startLoading());
  const personalMediaGroups = await Api.getByParentId(null, true);
  const personalMediaGroupNodes = convertToMediaGroupNodes(personalMediaGroups);
  dispatch(loadMediaGroupsSuccess({ nodes: personalMediaGroupNodes }));
  dispatch(stopLoadingPrivate());
  const publicMediaGroups = await Api.getByParentId(null, false);
  const publicMediaGroupNodes = convertToMediaGroupNodes(publicMediaGroups);
  dispatch(loadMediaGroupsSuccess({ nodes: publicMediaGroupNodes }));
  dispatch(stopLoadingPublic());
});

export const searchMediaGroups = createAsyncThunk<void, { query: string }, ThunkConfig>('media-groups/search', async ({ query }, { dispatch, getState }) => {
  dispatch(startSearching());
  const mediaGroupEntities = await Api.search(query);
  const mediaGroupNodes = convertQueryResultToMediaGroupNodes(mediaGroupEntities);
  dispatch(searchMediaGroupsSuccess({ nodes: mediaGroupNodes }));
});

export const loadMediaGroups = createAsyncThunk<void, string, ThunkConfig>('media-groups/loadMediaGroups', async (parentId, { dispatch, getState }) => {
  dispatch(startFetching({ id: parentId }));
  const mediaGroupEntities = await Api.getByParentId(parentId);
  const mediaGroupNodes = convertToMediaGroupNodes(mediaGroupEntities);
  dispatch(loadMediaGroupsSuccess({ nodes: mediaGroupNodes }));
  dispatch(stopFetching({ id: parentId }));
});

export const selectMediaGroup = createAsyncThunk<void, MediaGroupNode, ThunkConfig>('media-groups/selectMediaGroup', async (mediaGroup, { dispatch, getState }) => {
  dispatch(selectMediaGroupSuccess({ node: mediaGroup }));
  const mediaGroupEntity = await Api.getById(mediaGroup.id);
  const mediaGroupNode = convertToMediaGroupNode(mediaGroupEntity);
  dispatch(selectMediaGroupSuccess({ node: mediaGroupNode }));
});

export const getSelectedMediaGroup = (state: RootState) => {
  return state.mediaGroups.activeMediaGroup ? state.mediaGroups.activeMediaGroup : undefined;
}

export const addMediaGroup = createAsyncThunk<void, { parentId?: string, name: string, type: number, isPersonal: boolean }, ThunkConfig>('media-groups/add', async ({ parentId, name, type, isPersonal }, { dispatch, getState }) => {
  const mediaGroupEntity = await Api.add(name, type, isPersonal, parentId);
  const mediaGroupNode = convertToMediaGroupNode(mediaGroupEntity);
  dispatch(addMediaGroupSuccess({ node: mediaGroupNode }));

  dispatch(
    pushToast({
      message: `Gruppen '${name}' är skapad`,
      variant: 'success',
      persist: false,
    }));
});

export const updateMediaGroup = createAsyncThunk<void, { node: MediaGroupNode }, ThunkConfig>('media-groups/update', async ({ node }, { dispatch, getState }) => {

  const state = getState();
  const updatedMediaGroupTreeNode = {
    ...state.mediaGroups.mediaGroups[node.id],
    name: node.name,
    parentId: node.parentId,
    isPersonal: node.isPersonal,
    description: node.description,
    parent: node.parent,
    text: node.text,
    droppable: node.droppable
  };

  dispatch(updateMediaGroupPreApi({ node: updatedMediaGroupTreeNode }));
  dispatch(updateActiveMediaGroup({ node: updatedMediaGroupTreeNode }));

  dispatch(
    pushToast({
      message: `Gruppen '${node.name}' är uppdaterad`,
      variant: 'success',
      persist: false,
    }));

  const updatedMediaGroup = await Api.update(node.id, node.parentId, node.name, node.isPersonal, node.description);
  const updatedMediaGroupNode = convertToMediaGroupNode(updatedMediaGroup);
  dispatch(updateMediaGroupSuccess({ node: updatedMediaGroupNode }));
  dispatch(updateActiveMediaGroup({ node: updatedMediaGroupNode }));
});


export const deleteMediaGroup = createAsyncThunk<void, { id: string }, ThunkConfig>('media-groups/delete', async ({ id }, { dispatch, getState }) => {
  dispatch(deleteMediaGroupSuccess({ id }));
  await Api.remove(id);
});

export const addImagesToMediaGroup = createAsyncThunk<void, { id: string, images: string[] }, ThunkConfig>('media-groups/addImages', async ({ id, images}, { dispatch, getState }) => {
  dispatch(addImagesToMediaPreApi({id, images}));

  const state = getState();
  let mediaGroup = state.mediaGroups.mediaGroups[id];

  dispatch(
    pushToast({
      message: `${images.length} bilder tillagda i gruppen '${mediaGroup.name}'`,
      variant: 'success',
      persist: false,
    }));

  const mediaGroupEntity = await Api.addImages(id, images);
  const mediaGroupNode = convertToMediaGroupNode(mediaGroupEntity);
  dispatch(addImagesToMediaGroupSuccess({ node: mediaGroupNode }));
  dispatch(updateActiveMediaGroup({ node: mediaGroupNode }));
});

export const deleteImagesFromMediaGroup = createAsyncThunk<void, { id: string, images: string[] }, ThunkConfig>('media-groups/deleteImages', async ({ id, images}, { dispatch, getState }) => {

  dispatch(deleteImagesFromMediaPreApi( { id: id, images: images} ));
  dispatch(
    pushToast({
      message: `${images.length} bilder borttagna från gruppen`,
      variant: 'success',
      persist: false,
  }));
  const mediaGroupEntity = await Api.deleteImages(id, images);
  const mediaGroupNode = convertToMediaGroupNode(mediaGroupEntity);
  dispatch(deleteImagesFromMediaGroupSuccess({ node: mediaGroupNode }));
  dispatch(updateActiveMediaGroup({ node: mediaGroupNode }));
});

export const isMediaGroupAFavorite = (id: string) => (state: RootState): boolean => {
  return state.mediaGroups.favorites.some(favorite => favorite.id === id);
}

export const isFetching = (id: string) => (state: RootState): boolean => {
  return state.mediaGroups.isFetching[id];
}

export const haveFetchingParent = (id: string) => (state: RootState): boolean => {
  const mediaGroup = state.mediaGroups.mediaGroups[id];
  if (mediaGroup && !mediaGroup.isPersonal) {
    if (isFetching(mediaGroup.id)(state)) {
      return true;
    } else {
      return haveFetchingParent(mediaGroup.parentId)(state);
    }
  }
  return false;
}

export const getFavoriteMediaGroups = (state: RootState) : MediaGroupNode[] => {
  const groupArray = Object.values(state.mediaGroups.favorites);

  return groupArray.map(root => ({
    ...root,
    parentId: "",
    images: [],
    type: 2,
    isPersonal: false, //
    children: [],
    parent: '0',
    text: root.name,
    description: '',
    droppable: false,
    createdById: '',
    createdByFullName: '',
    created: '',
    modifiedById: '',
    modified: '',
    modifiedByFullName: ''
  }));
}

export const getPrivateMediaGroups = (state: RootState) : MediaGroupNode[] => {
  const groupArray = Object.values(state.mediaGroups.mediaGroups);
  return groupArray.filter(group => group.parentId === null && group.isPersonal === true);
}

export const getPrivateQueriedMediaGroups = (state: RootState) : MediaGroupNode[] => {
  const groupArray = Object.values(state.mediaGroups.queryMediaGroups);
  return  groupArray.filter(group => group.parentId === null && group.isPersonal === true);
}

export const getPublicMediaGroups = (state: RootState) : MediaGroupNode[] => {
  var mediaGroups = state.mediaGroups.mediaGroups;
  const groupArray = Object.values(mediaGroups);
  var publicGroupArray = groupArray.filter(group => group.isPersonal === false);

  var x = publicGroupArray.filter(group => group.type === MediaGroupType.Folder && !publicGroupArray.some(inner => inner.parentId === group.id));
  for(var i = 0; i < x.length; i++) {
    publicGroupArray.push({
        ...{} as MediaGroupNode,
        id: `${x[i].id}__empty`,
        parentId: x[i].id,
        images: [],
        type: MediaGroupType.Empty,
        isPersonal: false,
        parent: x[i].id,
        text: 'Gruppen är tom',
        droppable: false,
      });
  }

  return publicGroupArray;
}

export const getPublicQueriedMediaGroups = (state: RootState) : MediaGroupNode[] => {
  var mediaGroups = state.mediaGroups.queryMediaGroups;
  const groupArray = Object.values(mediaGroups);
  return  groupArray.filter(group => group.isPersonal === false);
}

const convertToMediaGroupNode = (mediaGroupEntity: MediaGroupEntity): MediaGroupNode => {
  return {
    ...mediaGroupEntity,
    parent: mediaGroupEntity.parentId === null ? '0' : mediaGroupEntity.parentId,
    text: mediaGroupEntity.name,
    droppable: mediaGroupEntity.type === 1 ? true : false,
  };
}

const convertToMediaGroupNodes = (mediaGroupEntities: MediaGroupEntity[]): MediaGroupNode[] => {
  return mediaGroupEntities.map(entity => ({
    ...entity,
    parent: entity.parentId === null ? '0' : entity.parentId,
    text: entity.name,
    droppable: entity.type === 1 ? true : false,
  }));
}

const convertQueryResultToMediaGroupNodes = (mediaGroupEntities: MediaGroupEntity[]): MediaGroupNode[] => {
  return mediaGroupEntities.map(entity => ({
    ...entity,
    parent: '0',
    text: entity.name,
    droppable: false,
  }));
}
export default mediaGroupsSlice.reducer;
