import React, {useState} from "react";
import {Box, Checkbox, ListItem, TextField, Typography} from "@mui/material";
import { useAppDispatch } from "../../../hooks/useAppRedux";
import { addMediaGroup } from '../mediaGroupsSlice';
import { CaseDialog } from "../../cases/layoutComponents/CaseDialog";
import { MediaGroupNode } from '../Types';

interface AddGroupDialogProps {
  isVisible: boolean,
  isPersonal: boolean,
  parentClipbook?: MediaGroupNode,
  closeDialog: () => void;
}

export const AddGroupDialog: React.FC<AddGroupDialogProps> = ({ isVisible, isPersonal, parentClipbook, closeDialog }) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>('');
  const [isFolder, setIsFolder] = useState<boolean>(false);
  const title = isPersonal ? 'privat grupp' : (parentClipbook ? `grupp under: ${parentClipbook.name}` : 'publik grupp');
  const titleText = `Skapa ${title}`;

  const onSave = () => {
    if (name !== '') {
      dispatch(addMediaGroup({ name: name, parentId: parentClipbook?.id, type: isFolder ? 1 : 2, isPersonal: isPersonal }));
      setName('');
      setIsFolder(false);
      closeDialog();
    }
  }

  const onClose = () => {
    setName('');
    setIsFolder(false);
    closeDialog();
  }

  if (!isVisible) {
    return (<></>);
  }

  return (
    <>
      <CaseDialog isVisible={isVisible} onSave={onSave} onClose={onClose}>
        <Box style={{width: "400px"}}>
          <Box>
            <Box>
              <Typography variant="h5">{titleText}</Typography>
            </Box>
          </Box>
          <Box>
            <ListItem>
              <TextField
                value={name}
                onChange={e => setName(e.currentTarget.value)}
                placeholder="Namn"
                fullWidth
                sx={{pl: 2}}
                inputProps={{style: {fontSize: '0.8rem'}}}
                onKeyDown={e => {
                  if (name.trim() !== '' && e.key === 'Enter') {
                    onSave();
                  }
                }}
              />
            </ListItem>
            { !isPersonal && (
              <ListItem>
                <Checkbox checked={isFolder} onChange={() => setIsFolder(!isFolder)}/> Skapa som mapp
              </ListItem>
            )}
          </Box>
        </Box>
      </CaseDialog>
    </>
  );
};
