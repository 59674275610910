import React, {useEffect, useState} from "react";
import styles from "./CustomNode.module.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/useAppRedux";
import {deleteMediaGroup, haveFetchingParent, isFetching, isMediaGroupAFavorite, loadMediaGroups } from "../mediaGroupsSlice";
import {ExpandLess, ExpandMore, MoreVert, Style} from "@mui/icons-material";
import {Box, CircularProgress, IconButton, useTheme} from "@mui/material";
import { CustomNodeTypeIcon } from "./CustomNodeTypeIcon";
import { CustomNodeContextMenu } from "./CustomNodeContextMenu";
import {ContextMenuChoice, MediaGroupNode} from "../Types";
import { AddGroupDialog } from "./AddGroupDialog";
import { RenameMediaGroupDialog } from "./RenameMediaGroupDialog";
import { MediaGroupType } from "../../../api/mediaGroupApi";

export interface CustomNodeProps {
  node: MediaGroupNode;
  isEditMode: boolean;
  isSelected: boolean;
  isQueryMode: boolean;
  disableFavoriteToggle?: boolean,
  disableSelected: boolean,
  disableOnSelect?: boolean,
  depth: any;
  isOpen: boolean;
  onSelect: (node: any) => void;
  onToggle: (id: string) => void;
}

export const CustomNode: React.FC<CustomNodeProps> = ({ ...props }) => {
  const palette = useTheme();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const indent = props.depth * 24;
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [showRenameDialog, setShowRenameDialog] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const isFetchingData = useAppSelector(isFetching(props.node.id));
  const haveFetchingParentData = useAppSelector(haveFetchingParent(props.node.parentId));
  const isFavorite = useAppSelector(isMediaGroupAFavorite(props.node.id));

  useEffect(() => {
    setShowIcon(isFavorite ? true : false);
  }, [props.node])

  const handleToggle = (e: any) => {
    e.stopPropagation();
    if (!props.isOpen) {
      dispatch(loadMediaGroups(props.node.id));
    }
    props.onToggle(props.node.id);
  };

  const handleSelect = () => {
    if (isDisabled) {
      return;
    }

    if (props.node.type === MediaGroupType.Folder) {
      dispatch(loadMediaGroups(props.node.id));
      props.onToggle(props.node.id);
    } else {
      if (props.isEditMode) {
        return;
      }
      if (props.disableSelected) {
        setIsDisabled(true);
      }
      props.onSelect(props.node);
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onContextMenuSelect = (contextMenuChoice: ContextMenuChoice) => {
    switch (contextMenuChoice) {
      case ContextMenuChoice.Add: {
        setShowAddDialog(true);
        break;
      }
      case ContextMenuChoice.Rename: {
        setShowRenameDialog(true);
        break;
      }
      case ContextMenuChoice.Delete: {
        if (window.confirm(`Är du säker på att du vill ta bort gruppen: ` + props.node.name + '?')) {
          dispatch(deleteMediaGroup({ id: props.node.id }));
        }
        break;
      }
    }
  }

  if (haveFetchingParentData === true) {
    return (<></>);
  }

  if (props.node.type === MediaGroupType.Empty) {
    return (
      <Box className={styles.emptyGroup}>
        <span className={styles.clipbookName}>{props.node.text}</span>
      </Box>
    );
  }

  return (
    <>
      <Box
        className={`tree-node ${styles.root} ${palette.palette.mode === 'dark' ? styles.rootDark : styles.rootLight}
        ${ props.isSelected && !props.disableSelected ? styles.isSelected : "" }
        ${ !props.isQueryMode && (props.isEditMode && (props.node.parentId !== null || props.node.isPersonal)) ? styles.draggable : "" }
        ${ isDisabled ? styles.isDisabled : "" }
        `}
        style={{paddingInlineStart: indent}}
        onClick={handleSelect}
      >
        <Box className={styles.labelGridItem} onMouseEnter={() => { if (!props.disableFavoriteToggle) setShowIcon(true) } } onMouseLeave={() => { if (!isFavorite) {setShowIcon(false)} } }>
          <Box className={styles.labelGridItemContainer}>
            <CustomNodeTypeIcon
              node={props.node}
              show={showIcon}
              isDisabled={isDisabled}
              disableFavoriteToggle={props.disableFavoriteToggle}
            />
            <span title={props.node.text} className={styles.clipbookName}>{props.node.text}</span>
          </Box>
        </Box>

        { props.isEditMode && (
          <IconButton onClick={openMenu}>
            <MoreVert />
          </IconButton>
        ) }

        <Box>
          {props.node.type == MediaGroupType.Folder && (
            <Box style={{cursor: 'pointer'}} onClick={handleToggle}>
              {props.isOpen === true ? (
                <>
                  {isFetchingData === true ? (<CircularProgress size={30} />) : (<ExpandLess />)}
                </>
              ) : (
                <ExpandMore />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <CustomNodeContextMenu
        anchorEl={anchorEl}
        isOpen={open}
        showCreate={props.node.type === MediaGroupType.Folder ? true : false}
        showDelete={!props.node.isPersonal && props.node.parentId === null ? false : true}
        onContextMenuSelect={(contextMenuChoice) => {
          onContextMenuSelect(contextMenuChoice);
          setAnchorEl(null);
        }}
        closeMenu={() => { setAnchorEl(null) }}
      />

      {props.isEditMode && (
        <>
          <AddGroupDialog
            isPersonal={props.node.isPersonal}
            isVisible={showAddDialog}
            parentClipbook={props.node}
            closeDialog={() => {
              setShowAddDialog(false)
            }}
          />

          <RenameMediaGroupDialog
            isVisible={showRenameDialog}
            treeNode={props.node}
            closeDialog={() => {
              setShowRenameDialog(false)
            }}
          />
        </>
      )}

    </>
  );
};
