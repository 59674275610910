import { FilterableCase, FilterableImage, PropClauseResolverConfig } from '../../../../api/filterTypes';
import { SearchComparison } from '../../../../api/searchApiTypes';
import {
  imageActiveIntervalPropClauseResolver,
  imageCreatedIntervalPropClauseResolver,
} from './dateIntervalPropClauseResolver';
import { datePropClauseResolver } from './datePropClauseResolver';
import { listPropClauseResolver } from './listPropClauseResolver';
import { stringPropClauseResolver } from './stringPropClauseResolver';

export const CasePropClauseResolverConfig: PropClauseResolverConfig<FilterableCase> = {
  // Shared between Image and Case
  photographer: stringPropClauseResolver,
  stylist: stringPropClauseResolver,
  retouchedBy: stringPropClauseResolver,

  // string comparison
  Case_CaseState: stringPropClauseResolver,
  Case_OwnerId: stringPropClauseResolver,
  Case_ProjectTagId: stringPropClauseResolver,
  Case_SystemTagIds: listPropClauseResolver(),
  type: stringPropClauseResolver,

  // Datetime
  plannedStart: datePropClauseResolver(SearchComparison.Gt),
  plannedEnd: datePropClauseResolver(SearchComparison.Lt),
};

export const ImagePropClauseResolverConfig: PropClauseResolverConfig<FilterableImage> = {
  // Shared between Image and Case
  photographer: stringPropClauseResolver,
  stylist: stringPropClauseResolver,
  retouchedBy: stringPropClauseResolver,

  // string comparison
  classification: stringPropClauseResolver,
  Image_ImageOrigin: stringPropClauseResolver,
  Image_ProjectTagId: stringPropClauseResolver,
  Image_SystemTagIds: listPropClauseResolver(),
  state: stringPropClauseResolver,
  type: stringPropClauseResolver,
  Image_Ids: stringPropClauseResolver,

  // Datetime
  CustomFilter_ActiveInterval: imageActiveIntervalPropClauseResolver,
  CustomFilter_CreatedInterval: imageCreatedIntervalPropClauseResolver,
};
