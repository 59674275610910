import React from 'react';

import {
  Box,
} from '@mui/material';

import { useAppSelector } from '../../hooks/useAppRedux';
import { getSelectedMediaGroup } from './mediaGroupsSlice';
import { MediaGroupSidebar } from './MediaGroupSidebar';
import { SelectedMediaGroupView } from './SelectedMediaGroupView';

export const MediaGroupView: React.FC = () => {
  let selectedMediaGroup = useAppSelector(getSelectedMediaGroup);

  return (
    <>
      <Box style={{display: 'flex'}}>

        <MediaGroupSidebar />

        {selectedMediaGroup && (
          <SelectedMediaGroupView />
        )}
      </Box>
    </>
  );
};
