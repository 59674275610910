import React from "react";
import { DndProvider } from "react-dnd";
import {
  Tree,
  MultiBackend,
  getBackendOptions,
  NodeModel
} from "@minoru/react-dnd-treeview";
import { CustomNode } from "./CustomNode";
import { CustomDragPreview } from "./CustomDragPreview";
import styles from "./MediaGroupLists.module.css";
import {useAppDispatch, useAppSelector } from "../../../hooks/useAppRedux";
import {
  getSelectedMediaGroup,
  updateMediaGroup
} from "../mediaGroupsSlice";
import { MediaGroupNode } from "../Types";
import { MediaGroupListHeader } from "./MediaGroupListHeader";
import {shallowEqual, useSelector } from "react-redux";
import {CircularProgress, useTheme} from "@mui/material";
import { RootState } from "../../../app/rootReducer";
import { MediaGroupType } from "../../../api/mediaGroupApi";

export interface MediaGroupListsProps {
  isEditMode: boolean;
  isQueryMode: boolean;
  privateGroups: MediaGroupNode[];
  publicGroups: MediaGroupNode[];
  disableFavoriteToggle: boolean;
  disableSelected: boolean;
  disableOnSelect: boolean;
  onSelect: (treeNode: MediaGroupNode) => void;
}

export const MediaGroupLists: React.FC<MediaGroupListsProps> = ({ isEditMode, isQueryMode, privateGroups, publicGroups, disableFavoriteToggle, disableSelected, disableOnSelect, onSelect }) => {
  const palette = useTheme();
  const dispatch = useAppDispatch();
  const activeMediaGroup = useAppSelector(getSelectedMediaGroup);
  const isLoading = useAppSelector((state: RootState) => state.mediaGroups.isLoading);
  const isSearching = useAppSelector((state: RootState) => state.mediaGroups.isSearching);

  const handleDrop = (newTree: any, e: any) => {
    const { dragSource, dropTarget, monitor } = e;

    if (!dropTarget) {
      return;
    }

    if (dragSource.isPersonal) {
      if (window.confirm(`Är du säker på att du vill flytta denna grupp från privat till publik: ` + dragSource.name + '?')) {
        moveMediaGroup(dragSource, dropTarget);
      }
    } else {
      moveMediaGroup(dragSource, dropTarget);
    }
  };

  const moveMediaGroup = (dragSource: any, dropTarget: any) => {
    const updatedMediaGroup = {
      ...dragSource,
      parentId: dropTarget.id,
      parent: dropTarget.id,
      isPersonal: dropTarget.isPersonal ? true : false,
    };
    dispatch(updateMediaGroup({ node: updatedMediaGroup}));
  }

  return (
    <>
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>

        <div className={styles.app}>
          <MediaGroupListHeader
            title={'Mina grupper:'}
            isEditMode={isEditMode}
            isPersonal={true}
          />
          {isSearching && (<CircularProgress />)}
          {isLoading.private && (<CircularProgress />)}
          {privateGroups.length > 0 && (
              <div className={styles.treeContainer}>
                <Tree
                  tree={privateGroups}
                  rootId={'0'}
                  render={(node, {depth, isOpen, onToggle}) => (
                    <CustomNode
                      node={node as MediaGroupNode}
                      isEditMode={isEditMode}
                      isQueryMode={isQueryMode}
                      disableFavoriteToggle={disableFavoriteToggle}
                      disableSelected={disableSelected}
                      disableOnSelect={disableOnSelect}
                      depth={depth}
                      isOpen={isOpen}
                      isSelected={node.id === activeMediaGroup?.id}
                      onToggle={onToggle}
                      onSelect={onSelect}
                    />
                  )}
                  sort={false}
                  dragPreviewRender={(monitorProps) => (
                    <CustomDragPreview monitorProps={monitorProps}/>
                  )}
                  canDrag={(node: any) => {
                    if (!isEditMode || isQueryMode) {
                      return false;
                    }
                    return true;
                  }}
                  canDrop={() => {
                    return false
                  }}
                  onDrop={() => {
                  }}
                  classes={{
                    draggingSource: styles.draggingSource,
                    dropTarget: palette.palette.mode === 'dark' ? styles.dropTargetDark : styles.dropTargetLight
                  }}
                />
              </div>
          )}

          <MediaGroupListHeader
            title={'Delade grupper:'}
            isEditMode={isEditMode}
            isPersonal={false}
          />
          {isSearching && (<CircularProgress />)}
          {isLoading.public && (<CircularProgress />)}
          {publicGroups.length > 0 && (
            <Tree
              tree={publicGroups}
              rootId={'0'}
              render={(node, {depth, isOpen, onToggle}) => (
                <CustomNode
                  node={node as MediaGroupNode}
                  isEditMode={isEditMode}
                  isQueryMode={isQueryMode}
                  disableFavoriteToggle={disableFavoriteToggle}
                  disableSelected={disableSelected}
                  disableOnSelect={disableOnSelect}
                  depth={depth}
                  isOpen={isOpen}
                  isSelected={node.id === activeMediaGroup?.id}
                  onToggle={onToggle}
                  onSelect={onSelect}
                />
              )}
              dragPreviewRender={(monitorProps) => (
                <CustomDragPreview monitorProps={monitorProps}/>
              )}
              canDrag={(node: any) => {
                if (!isEditMode || isQueryMode) {
                  return false;
                }
                if (node.type === MediaGroupType.Empty) {
                  return false;
                }
                return node.parent === '0' ? false : true;
              }}
              onDrop={handleDrop}
              classes={{
                draggingSource: styles.draggingSource,
                dropTarget: palette.palette.mode === 'dark' ? styles.dropTargetDark : styles.dropTargetLight
              }}
            />
          )}
        </div>
      </DndProvider>
    </>
  );
}
