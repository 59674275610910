import React from 'react';
import App, { Routes } from './App';
import { Layout } from './Layout';
import store from './app/store';
import { Provider } from 'react-redux';
import 'typeface-roboto';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { sv } from 'date-fns/locale';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { Login } from './features/login/Login';
import { CasesTable } from './features/cases/CasesTable';
import { EditCase } from './features/cases/EditCase';
import { CreateCase } from './features/cases/CreateCase';
import { CaseCalendarPlan } from './features/cases/CaseCalendarPlan';
import { CaseKanbanPlan } from './features/kanban/CaseKanbanPlan';
import { ImagesGrid } from './features/images/ImagesGrid';
import { ProductContextualCaseKanbanPlan } from './features/kanban/ProductContextualCaseKanbanPlan';
import { ClipbookView } from './features/clipbooks/ClipbookView';
import { SelectionContext, SelectionContextProvider } from './features/images/selectedImagesContext/selectionContextContext';
import { MediaGroupView } from './features/mediaGroups/MediaGroupView';

axios.defaults.xsrfCookieName = 'CSRF';
axios.defaults.xsrfHeaderName = '__Host-X-CSRF-Token';

Object.typedKeys = Object.keys;
Object.typedEntries = Object.entries;

const toastRef = React.createRef<SnackbarProvider>();

const onClickDismiss = (key: SnackbarKey) => () => {
  if (toastRef != null && toastRef.current != null) {
    toastRef.current.closeSnackbar(key);
  }
};

const dismissButton = (key: SnackbarKey) => (
  <IconButton onClick={onClickDismiss(key)} size="small">
    <Close style={{ color: 'white' }} />
  </IconButton>
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={Routes.Login} element={<Login />} />
      <Route element={<Layout />}>
        <Route path={Routes.Cases} element={<CasesTable />} />
        <Route path={Routes.EditCase} element={<EditCase />} />
        <Route path={Routes.NewCase} element={<CreateCase />} />
        <Route path={Routes.CalendarPlanning} element={<CaseCalendarPlan />} />
        <Route path={Routes.KanbanPlanning} element={<CaseKanbanPlan />} />
        <Route path={Routes.ProductContextualKanbanPlanning} element={<ProductContextualCaseKanbanPlan />} />
        <Route path={Routes.Images} element={<ImagesGrid />} />
        <Route path={Routes.Clipbooks} element={<ClipbookView />} />
        <Route path={Routes.MediaGroups} element={<MediaGroupView />} />
        <Route path={Routes.Splat} element={<Navigate to={Routes.Images} />} />
      </Route>
    </>,
  ),
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <SelectionContextProvider selectionContext={SelectionContext.Global}>
          <SnackbarProvider
            ref={toastRef}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            action={dismissButton}
          >
            <App router={router} />
          </SnackbarProvider>
        </SelectionContextProvider>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>,
);
