import React, {useEffect, useState} from 'react';

import {
  Box,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack, Typography,
  useTheme
} from '@mui/material';

import styles from './SelectedMediaGroupView.module.css';

import { Image, ImageState } from '../../api/caseApiTypes';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { ImageCard } from '../image/imageCard/ImageCard';
import { CreateRetouchCaseFromSelectionButton } from '../images/selectedImagesContext/CreateRetouchCaseFromSelectionButton';
import { DownloadSelectedMediaButton } from '../images/selectedImagesContext/DownloadSelectedMediaButton';
import { ImageFullScreenDisplay } from '../images/ImageFullScreenDisplay';
import { ImagesGridDetailsView } from '../images/detailsView/ImagesGridDetailsView';
import { useClearSelectedMedia } from '../images/selectedImagesContext/useClearSelectedMedia';
import { getSelectedMediaGroup } from './mediaGroupsSlice';
import { setSelectedMedia, resetSelectedContext, search } from '../images/imagesSlice';
import { useSelectionContext } from '../images/selectedImagesContext/selectionContextContext';
import { OutlinedLabel } from '../../helpers/OutlinedLabel';
import {CheckBox, CheckBoxOutlineBlank, Info, InfoOutlined, Photo} from '@mui/icons-material';
import { RemoveSelectedFromClipbookIconButton } from '../images/selectedImagesContext/RemoveSelectedFromClipbookIconButton';
import { MediaGroupDetailsView } from './components/MediaGroupDetailsView';
import { AddSelectedToClipbookIconButton } from '../images/selectedImagesContext/AddSelectedToClipbookIconButton';
import { useUserFilters } from '../../hooks/useUserFilters';
import { SearchContext, useImageSearchState } from '../../hooks/useSearchState';
import {SearchFilters, SearchFilterTranslator } from '../sidebar/filter/searchFilterTranslator';
import { FilterableImage } from '../../api/filterTypes';
import { ImagePropClauseResolverConfig } from '../sidebar/filter/propClauseResolver/resolverConfigs';

const translator = new SearchFilterTranslator<FilterableImage>(ImagePropClauseResolverConfig);

enum ImageProductionApprovalState {
  Approved,
  ApprovedWhenPublished,
  NotApproved,
}

function getIsApproved(image: Image): ImageProductionApprovalState {
  if (image.state === ImageState.Approved) {
    return ImageProductionApprovalState.Approved;
  } else if (image.state === ImageState.ProofedOk) {
    // If the image is in the state Proofed OK, and the publication date has been reached, the image is
    // considered Approved.
    if (image.publicationDate && new Date(image.publicationDate) < new Date()) {
      return ImageProductionApprovalState.Approved;
    } else {
      return ImageProductionApprovalState.ApprovedWhenPublished;
    }
  }
  return ImageProductionApprovalState.NotApproved;
}

export const SelectedMediaGroupView: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showInformation, setShowInformation] = useState<boolean>(false);
  const { palette } = useTheme();

  const isLightMode = palette.mode === 'light';

  let selectedMediaGroup = useAppSelector(getSelectedMediaGroup);

  const selectionContext = useSelectionContext();
  const selectedImages = useAppSelector(state => state.images.selected[selectionContext]);
  const anySelected = selectedImages.length > 0;
  const clearSelection = () => dispatch(resetSelectedContext(selectionContext));
  useClearSelectedMedia();

  const { items: images, count, isSearching } = useImageSearchState(SearchContext.MediaGroup);
  const userFilters = useUserFilters(SearchContext.MediaGroup);

  useEffect(() => {
    if (userFilters && selectedMediaGroup) {

      const imageGridFilters: SearchFilters<FilterableImage> = {
        defaultFilters: {
          Image_Ids: { value: selectedMediaGroup?.images || [] },
        },
      };

      if (selectedMediaGroup.images.length > 0) {
        dispatch(
          search({
            filterTree: translator.getClauses({
              ...imageGridFilters,
              userFilters
            }),
            searchContext: SearchContext.MediaGroup,
          }),
        );
      }
    }
  }, [userFilters, selectedMediaGroup]);

  useEffect(() => {
    dispatch(resetSelectedContext(selectionContext));
  }, [dispatch, userFilters, selectionContext]);

  const getStyle = (image: Image) => {
    const style: React.CSSProperties = {
      borderStyle: 'solid',
      borderWidth: '1px',
    };

    const approvedState = getIsApproved(image);
    if (approvedState === ImageProductionApprovalState.Approved) {
      style.borderColor = isLightMode ? '#fff' : 'transparent';
    } else if (approvedState === ImageProductionApprovalState.ApprovedWhenPublished) {
      style.borderColor = isLightMode ? '#fff6bb' : '#888600';
      style.backgroundColor = isLightMode ? '#fffef1' : '#201d0c';
    } else {
      style.borderColor = isLightMode ? '#fbb' : '#800';
      style.backgroundColor = isLightMode ? '#fff1f1' : '#200c0c';
    }

    return style;
  };

  const getImageCard = (image: Image) => {
    return <ImageCard key={image.id} style={getStyle(image)} image={image} disableRemoval />;
  };

  const gridClasses = [styles.imageGrid];
  if (anySelected || showInformation) {
    gridClasses.push(styles.compact);
  }

  const additionalButtons = anySelected && (
    <OutlinedLabel label="Markerade bilder">
      <AddSelectedToClipbookIconButton />
      <RemoveSelectedFromClipbookIconButton />
      <CreateRetouchCaseFromSelectionButton />
      <DownloadSelectedMediaButton />
    </OutlinedLabel>
  );

  const toogleInformation = () => {
    setShowInformation(!showInformation);
  }

  const additionalMediaGroupButtons = (
    <OutlinedLabel label="Grupp information">
      <div style={{width: '6em'}}>
        <IconButton onClick={toogleInformation}>{showInformation ? <Info /> : <InfoOutlined />}</IconButton>
      </div>
    </OutlinedLabel>
  )

  const toogleAllSelectedButton = () => {
    if (anySelected) {
      clearSelection();
    } else {
      dispatch(setSelectedMedia({ selectionContext, media: images }));
    }
  };

  return (
    <>
        {selectedMediaGroup && (
          <>
            <Box className={styles.container} style={{flexGrow: '1'}}>
              <Paper>
                <Stack sx={{p: '1em'}} direction="row">
                  <Box style={{lineHeight: '3em'}}>{selectedMediaGroup?.name}</Box>
                  { selectedMediaGroup?.images.length > 0 && (
                    <IconButton onClick={toogleAllSelectedButton}>
                      {anySelected ? <CheckBox/> : <CheckBoxOutlineBlank/>}
                    </IconButton>
                  )}
                  <Box sx={{flexGrow: 1}}/>
                  <div>{additionalMediaGroupButtons}</div>
                  <div>{additionalButtons}</div>
                </Stack>
              </Paper>
              <Box className={styles.content}>
                { isSearching && (
                  <CardMedia title={'none...'} component={CircularProgress} disableShrink />
                )}

                { selectedMediaGroup?.images.length === 0 && (
                  <Box className={styles.emptyGroup}>
                    <Photo sx={{height: '100px', width: '100px'}} />
                    <Typography variant="subtitle1">Gruppen är tom</Typography>
                  </Box>
                )}

                { selectedMediaGroup?.images.length > 0 && images.length > 0 && (
                  <Grid container sx={{gap: '0.5em'}} className={gridClasses.join(' ')}>
                    {images.map(getImageCard)}
                  </Grid>
                )}

                {(anySelected || showInformation) && (
                  <Box className={styles.details}>
                    {showInformation && (
                      <MediaGroupDetailsView
                        onClose={() => { setShowInformation(false) }}
                      />
                    )}

                    {anySelected && (
                      <>
                        <ImagesGridDetailsView onClose={clearSelection}/>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            <ImageFullScreenDisplay/>
          </>
        )}
    </>
  );
};
