import React from "react";
import {ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {CreateNewFolder, Delete, Edit} from "@mui/icons-material";
import { ContextMenuChoice } from "../Types";

export interface CustomNodeContextMenuProps {
  isOpen: boolean;
  anchorEl: any;
  showCreate: boolean;
  showDelete: boolean;
  closeMenu: () => void;
  onContextMenuSelect: (contextMenuChoice: ContextMenuChoice) => void;
}

export const CustomNodeContextMenu: React.FC<CustomNodeContextMenuProps> = ({ isOpen, anchorEl, showCreate, showDelete, closeMenu, onContextMenuSelect }) => {

  return (
    <Menu anchorEl={anchorEl} open={isOpen} onClose={closeMenu}>
      {showCreate && (
        <MenuItem onClick={(event) => { event.stopPropagation(); onContextMenuSelect(ContextMenuChoice.Add)}}>
          <ListItemIcon>
            <CreateNewFolder />
          </ListItemIcon>
          <ListItemText>Skapa grupp</ListItemText>
        </MenuItem>
      )}
      <MenuItem onClick={(event) => { event.stopPropagation(); onContextMenuSelect(ContextMenuChoice.Rename)}}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>Ändra namn</ListItemText>
      </MenuItem>
      {showDelete && (
        <MenuItem onClick={(event) => { onContextMenuSelect(ContextMenuChoice.Delete) }}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>Ta bort grupp</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
}
