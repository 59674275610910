import { BookmarkAdd } from '@mui/icons-material';
import {Box, IconButton, Tab, Typography} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import React, {useState} from "react";
import { CaseDialog } from '../../cases/layoutComponents/CaseDialog';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { MediaGroupFavorites } from './MediaGroupFavorites';
import { addImagesToMediaGroup, getFavoriteMediaGroups, getPrivateMediaGroups, getPublicMediaGroups} from '../mediaGroupsSlice';
import { MediaGroupLists } from './MediaGroupLists';
import { MediaGroupNode } from '../Types';
import styles from "./AddToMediaGroupIconButton.module.css";

export type Props = {
  imageIds: string[];
  title?: string;
};

enum TabListType {
  favorites = '1',
  all = '2'
}

export function AddToMediaGroupIconButton({ imageIds, title }: Props) {
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const favoriteMediaGroups = useAppSelector(getFavoriteMediaGroups);
  const privateGroups = useAppSelector(getPrivateMediaGroups);
  const publicGroups = useAppSelector(getPublicMediaGroups);
  const [value, setValue] = React.useState(favoriteMediaGroups.length > 0 ? TabListType.favorites : TabListType.all);

  const clipbook = () => {
    setShowDialog(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: TabListType) => {
    setValue(newValue);
  };

  const bildText = imageIds.length === 1 ? 'bilden' : 'bilderna';
  const buttonText = title ?? `Lägg till ${bildText} i en grupp`;

  return (
    <>
      <IconButton title={buttonText} onClick={clipbook}>
        <BookmarkAdd />
      </IconButton>

      <CaseDialog isVisible={showDialog} onClose={() => { setShowDialog(false) }}>
        <Box style={{width: "400px"}}>
          <Box>
            <Box>
              <Typography variant="h5">Lägg till bilder i en grupp:</Typography>
            </Box>
          </Box>
          <Box style={{paddingTop: '1em'}}>

            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  { favoriteMediaGroups?.length > 0 && (
                    <Tab label="Favoriter" value={TabListType.favorites} />
                  )}
                  <Tab label="Alla grupper" value={TabListType.all} />
                </TabList>
              </Box>
              { favoriteMediaGroups?.length > 0 && (
                <TabPanel value={TabListType.favorites} className={styles.tabPanel}>
                  <MediaGroupFavorites
                    favorites={favoriteMediaGroups}
                    onSelect={(treeNode: MediaGroupNode) => {
                      dispatch(addImagesToMediaGroup({ id: treeNode.id, images: imageIds}))
                    }}
                  />
                </TabPanel>
              )}
              <TabPanel value={TabListType.all} className={styles.tabPanel}>
                <MediaGroupLists
                  isEditMode={false}
                  isQueryMode={false}
                  privateGroups={privateGroups}
                  publicGroups={publicGroups}
                  disableFavoriteToggle={true}
                  disableSelected={true}
                  disableOnSelect={true}
                  onSelect={(node: MediaGroupNode) => {
                    dispatch(addImagesToMediaGroup({ id: node.id, images: imageIds}))
                  }}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </CaseDialog>
    </>
  );
}
