import { Card, CardActions, Typography } from '@mui/material';
import { AddSelectedToClipbookIconButton } from './AddSelectedToClipbookIconButton';
import { CreateRetouchCaseFromSelectionButton } from './CreateRetouchCaseFromSelectionButton';
import { DownloadSelectedMediaButton } from './DownloadSelectedMediaButton';
import { RemoveSelectedFromClipbookIconButton } from './RemoveSelectedFromClipbookIconButton';

export const SelectionActionButtonsCard: React.FC = () => {
  return (
    <Card style={{ padding: '1rem', marginBottom: '2rem' }}>
      <Typography variant="subtitle2">{'Hantera markerade bilder'}</Typography>
      <CardActions>
        <AddSelectedToClipbookIconButton />
        <CreateRetouchCaseFromSelectionButton />
        <DownloadSelectedMediaButton />
      </CardActions>
    </Card>
  );
};
