import React, {useEffect, useState} from 'react';

import {
  Box,
  Button,
  IconButton,
} from '@mui/material';

import styles from './MediaGroupDetailsView.module.css';
import { CaseTextField } from '../../cases/formFieldComponents/CaseTextField';
import { formatDateTime } from '../../date/DateFormatter';
import { Close } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import {getSelectedMediaGroup, updateMediaGroup } from '../mediaGroupsSlice';

export interface MediaGroupDetailsViewProps {
  onClose: () => void;
};

export const MediaGroupDetailsView: React.FC<MediaGroupDetailsViewProps> = ( { onClose }) => {
  const dispatch = useAppDispatch();
  const activeMediaGroup = useAppSelector(getSelectedMediaGroup);
  const [description, setDescription] = useState<string | undefined>(activeMediaGroup?.description);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setDescription(activeMediaGroup?.description);
  }, [activeMediaGroup]);

  const handleChanges = (newValue: string) => {
    setIsDirty(activeMediaGroup?.description !== newValue);
    setDescription(newValue);
  }

  const onUpdate = () => {
    if (activeMediaGroup === undefined) {
      return;
    }

    const updatedMediaGroup = {
      ...activeMediaGroup,
      description: description === undefined ? '' : description
    };

    dispatch(updateMediaGroup({ node: updatedMediaGroup }));
    setIsDirty(false);
  }

  return (
    <>
      <Box>
        <Box className={styles.groupInformationHeader}>
          MEDIAGRUPPINFORMATION
        </Box>
        <Box className={styles.groupInformationContainer}>
          <table>
            <tbody>
            <DataRow label="Namn">{activeMediaGroup?.name}</DataRow>
            <DataRow label="Skapad">{formatDateTime(activeMediaGroup?.created)}</DataRow>
            <DataRow label="Skapad av">{activeMediaGroup?.createdByFullName}</DataRow>
            <DataRow label="Uppdaterad">{formatDateTime(activeMediaGroup?.modified)}</DataRow>
            <DataRow label="Uppdaterad av">{activeMediaGroup?.modifiedByFullName}</DataRow>
            <DataRow label="Beskrivning">
              <CaseTextField
                variant="outlined"
                multiline
                label={'Beskrivning'}
                name="description"
                minRows={5}
                value={description || ''}
                setValue={handleChanges}
                disabled={false}
                fullWidth
              />
              {isDirty && (
                <Button onClick={onUpdate} style={{float: 'right'}}>
                  Uppdatera
                </Button>
              )}
            </DataRow>
            </tbody>
          </table>
          <IconButton onClick={onClose} size="small" sx={{ position: 'absolute', right: 10, top: '-50px' }}>
            <Close />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

const DataRow: React.FC<{ label: string; children?: React.ReactNode }> = ({label, children}) => (
  <tr>
    <td>{label}:</td>
    <td>{children}</td>
  </tr>
);

