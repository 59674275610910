import axios from "axios";

export interface MediaGroupEntity {
  id: string;
  name: string;
  description: string;
  isPersonal: boolean;
  parentId: string;
  images: string[];
  type: MediaGroupType;
  createdById: string;
  created: string;
  createdByFullName: string;
  modifiedById: string
  modified: string;
  modifiedByFullName: string;
}

export enum MediaGroupType {
  Empty = 0,
  Folder = 1,
  Group = 2
}

const BASEURL = '/api/case/media-groups';

export async function search(query: string): Promise<MediaGroupEntity[]> {
  let url = `${BASEURL}/search?query=${query}`;

  const { data } = await axios.get<MediaGroupEntity[]>(url);

  return data;
}

export async function getByParentId(parentId: string | null, isPersonal: boolean = false): Promise<MediaGroupEntity[]> {
  let url = `${BASEURL}?isPersonal=${isPersonal}`;

  if (parentId !== null) {
    url += `&parentId=${parentId}`;
  }

  const { data } = await axios.get<MediaGroupEntity[]>(url);

  return data;
}

export async function getById(id: string): Promise<MediaGroupEntity> {
  const url = `${BASEURL}/${id}`;

  const { data } = await axios.get<MediaGroupEntity>(url);

  return data;
}

export async function add(name: string, type: number, isPersonal: boolean, parentId?: string, images?: string[]): Promise<MediaGroupEntity> {
  const url = `${BASEURL}`;

  const { data } = await axios.post<MediaGroupEntity>(url, { parentId: parentId, name: name, type: type, isPersonal: isPersonal, images: images });

  return data;
}

export async function update(id: string, parentId: string, name: string, isPersonal: boolean, description: string): Promise<MediaGroupEntity> {
  const url = `${BASEURL}`;

  const { data } = await axios.put<MediaGroupEntity>(url, { id: id, parentId: parentId, isPersonal: isPersonal, name: name, description: description });

  return data;
}

export async function remove(id: string): Promise<MediaGroupEntity> {
  const url = `${BASEURL}/${id}`;

  const { data } = await axios.delete<MediaGroupEntity>(url);

  return data;
}

export async function addImages(id: string, images: string[]): Promise<MediaGroupEntity> {
  const url = `${BASEURL}/${id}/images`;

  const { data } = await axios.post<MediaGroupEntity>(url, { images: images });

  return data;
}

export async function deleteImages(id: string, images: string[]): Promise<MediaGroupEntity> {
  const url = `${BASEURL}/${id}/images`;

  const { data } = await axios.put<MediaGroupEntity>(url, { images: images });

  return data;
}
