import { useAppSelector } from '../../../hooks/useAppRedux';
import { AddToMediaGroupIconButton } from '../../mediaGroups/components/AddToMediaGroupIconButton';
import { useSelectionContext } from './selectionContextContext';

export function AddSelectedToClipbookIconButton() {
  const selectionContext = useSelectionContext();
  const selectedImages = useAppSelector(state => state.images.selected[selectionContext]);
  const selectedIds = selectedImages.map(m => m.id);
  const title = 'Lägg till valda bilder i en grupp';
  return <AddToMediaGroupIconButton imageIds={selectedIds} title={title} />;
}
