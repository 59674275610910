import React, {useEffect, useState} from "react";
import { Box, ListItem, TextField, Typography } from "@mui/material";
import { useAppDispatch } from "../../../hooks/useAppRedux";
import { updateMediaGroup } from '../mediaGroupsSlice';
import { CaseDialog } from "../../cases/layoutComponents/CaseDialog";
import { MediaGroupNode } from '../Types';

interface RenameMediaGroupDialogProps {
  isVisible: boolean,
  treeNode: MediaGroupNode,
  closeDialog: () => void;
}

export const RenameMediaGroupDialog: React.FC<RenameMediaGroupDialogProps> = ({ isVisible, treeNode, closeDialog }) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(treeNode.name);

  if (!isVisible || treeNode === undefined) {
    return (<></>);
  }

  const onSave = () => {
    if (name !== '') {
      const updatedMediaGroup = {
        ...treeNode,
        name: name
      };
      dispatch(updateMediaGroup({ node: updatedMediaGroup}));
      closeDialog();
    }
  }

  const onClose = () => {
    closeDialog();
  }

  return (
    <CaseDialog isVisible={isVisible} onSave={onSave} onClose={onClose}>
      <Box style={{width: "400px"}}>
        <Box>
          <Box>
            <Typography variant="h5">Ändra namn på grupp</Typography>
          </Box>
        </Box>
        <Box>
          <ListItem>
            <TextField
              value={name}
              onChange={e => setName(e.currentTarget.value)}
              placeholder="Namn"
              fullWidth
              sx={{pl: 2}}
              inputProps={{style: {fontSize: '0.8rem'}}}
              onKeyDown={e => {
                if (name.trim() !== '' && e.key === 'Enter') {
                  onSave();
                }
              }}
            />
          </ListItem>
        </Box>
      </Box>
    </CaseDialog>
  );
};
