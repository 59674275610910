import React, {useState} from "react";
import {Box, CircularProgress, IconButton, InputAdornment} from "@mui/material";
import { Clear, Edit, EditOff, Search } from "@mui/icons-material";
import {CaseTextField} from "../cases/formFieldComponents/CaseTextField";
import styles from './MediaGroupSidebar.module.css';
import {useAppDispatch, useAppSelector } from "../../hooks/useAppRedux";
import {selectMediaGroup, searchMediaGroups, getPrivateMediaGroups, getPublicMediaGroups, getPrivateQueriedMediaGroups, getPublicQueriedMediaGroups} from './mediaGroupsSlice';
import { MediaGroupLists } from "./components/MediaGroupLists";
import { MediaGroupNode } from './Types';
import { RootState } from "../../app/rootReducer";

export const MediaGroupSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const isSearching = useAppSelector((state: RootState) => state.mediaGroups.isSearching);
  const privateQueriedMediaGroups = useAppSelector(getPrivateQueriedMediaGroups);
  const publicQueriedMediaGroups = useAppSelector(getPublicQueriedMediaGroups);
  const privateGroups = useAppSelector(getPrivateMediaGroups);
  const publicGroups = useAppSelector(getPublicMediaGroups);

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleChange = (value: string) => {
    if (value !== '') {
      dispatch(searchMediaGroups( { query: value } ));
    }
    setQuery(value);
  };

  return (
    <>
      <Box className={styles.container}>
        <CaseTextField
          aria-label='Query'
          value={query}
          setValue={value => handleChange(value)}
          autoFocus={true}
          label={'Sök'}
          onSubmit={submit}
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <IconButton onClick={_ => setQuery('')}>
                  <Clear/>
                </IconButton>
                <Search/>
                {isSearching && <CircularProgress size={30} className={styles.loading} />}
                <IconButton onClick={_ => {
                  setIsEditMode(!isEditMode)
                }}>
                  {
                    isEditMode ? (
                      <EditOff/>
                    ) : (
                      <Edit/>
                    )
                  }
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <MediaGroupLists
          isEditMode={isEditMode}
          isQueryMode={query === '' ? false : true}
          privateGroups={query === '' ? privateGroups : privateQueriedMediaGroups}
          publicGroups={query === '' ? publicGroups : publicQueriedMediaGroups}
          disableFavoriteToggle={false}
          disableSelected={false}
          disableOnSelect={false}
          onSelect={(node: MediaGroupNode) => {
            dispatch(selectMediaGroup(node));
          }}
        />
      </Box>
    </>
  )
};

